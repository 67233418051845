import { Injectable } from "@angular/core";
import { CtAuthenticationService } from "@ctsolution/ct-authentication";
import { FLOW_TYPE } from "../enums/flow-type.enum";
import { GeneralService } from "./general.service";
import { FlowController } from "../controllers/flow.controller";
import { Flow } from "../classes/flow";
import { SendFlowAlertComponent } from "../../pages/shipment/send-flow/send-flow-alert/send-flow-alert.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { FlowDetailRequest } from "../classes/flow-detail-request";
import { JwtService } from "./jwt.service";
import { FLOW_STATE } from "../enums/flow-state";
import { MenuItemsService } from "../../shared/menu/menu-items.service";
import { StepSaveResult } from "../controllers/flow-steps.controller";

@Injectable({ providedIn: "root" })
export class FlowService {

  activeFlow: Flow = Flow.create();

  constructor(
    private _authentication: CtAuthenticationService,
    private _general: GeneralService,
    private flowController: FlowController,
    private _dialog: MatDialog,
    private _jwt: JwtService,
    private menuItemsService: MenuItemsService
  ) {
  }

  setupCurrentFlow(): Promise<Flow> {

    return new Promise<Flow>(async (resolve) => {

      let flowOid: number = 0;

      if (this.activeFlow.Oid) flowOid = this.activeFlow.Oid;

      if (!flowOid) flowOid = await this._jwt.getFlowDraftOid();

      if (flowOid) {

        const request: FlowDetailRequest = new FlowDetailRequest(flowOid);
        request.LoadFileData = true;

        this.flowController
          .detail(request)
          .then((result: Flow) => {

            this.activeFlow = Flow.create(result);
            resolve(this.activeFlow);

          });

      } else {

        resolve(this.activeFlow);

      }

    });

  }

  updateCurrentFlowValues(result: StepSaveResult) {

    if (result.FlowOid) {

      this.activeFlow
        .setFlowOid(result.FlowOid);

    }

    if (result.FlowState) {

      this.activeFlow
        .setFlowState(result.FlowState);

    }

    if (result.FlowType) {

      this.activeFlow
        .setFlowType(result.FlowType);

    }

    if (result.FlowFiles) {

      this.activeFlow
        .setFlowFiles(result.FlowFiles);

    }

    if (result.SendTypeSendCategory) {

      this.activeFlow
        .setSendTypeConfigurationSendTypeSendCategory(result.SendTypeSendCategory);

    }

    if (result.SendTypeOid) {

      this.activeFlow
        .setSendTypeConfigurationSendTypeOid(result.SendTypeOid);

    }

    if (result.SendTypeName) {

      this.activeFlow
        .setSendTypeConfigurationSendTypeName(result.SendTypeName);

    }  


  }

  cancelFlow(redirect: string[] | null = null) {

    return new Promise((resolve) => {

      this.flowController
        .deleteDraft()
        .then(() => {

          this._authentication
            .getClaims()
            .then(() => {

              if (redirect) {

                this._general.navigateTo(redirect);

              }

              this.resetFlow();

              resolve(true);

            });

        });

    });

  };

  continueFlow = () => this._general.navigateTo(["nuova-spedizione", this.getFlowDraftPath()]);

  resetFlow = () => this.activeFlow = Flow.create();

  openFlowAlertDialog = () => this._dialog.open(SendFlowAlertComponent, { disableClose: true });

  private getFlowDraftPath(): string {

    switch (this.activeFlow.Type) {

      case FLOW_TYPE.MULTIPLE_RECIPIENT:
      case FLOW_TYPE.MULTIPLE_RECIPIENT_DYNAMIC:
        return "multipla";

      case FLOW_TYPE.SINGLE_RECIPIENT:
      default:
        return "singola";

    }

  }

  getCartCount = (): Promise<any> => this.flowController
    .flowCount([FLOW_STATE.CART])
    .then((count: number) => this.menuItemsService.setupCartCount(count));

}
