export class SendSignatureDTO implements ISendSignature{

    constructor(){
        this._SignerInfo = new Info({
            first_name: '',
            last_name: '',
            phone_number: ''
        });
        this._Token = '';
    }
    
    private _SignerInfo: Info;
    public get SignerInfo(): Info {
        return this._SignerInfo;
    }
    public set SignerInfo(value: Info) {
        this._SignerInfo = value;
    }


    private _Token: string;
    public get Token(): string {
        return this._Token;
    }
    private set Token(value: string) {
        this._Token = value;
    }

    create(param: ISendSignature): SendSignatureDTO {
        var a = new SendSignatureDTO();
        a.SignerInfo = param.SignerInfo;
        a.Token = param.Token;
        return a;
    }

}

export interface ISendSignature {

    SignerInfo: Info;
    Token: string;

}



export class Info {

    constructor(info: IInfo){
        this.first_name = info.first_name;
        this.last_name = info.last_name;
        this.phone_number = info.phone_number;
    }

    first_name: string;
    last_name: string;
    phone_number: string;
}

export interface IInfo{
    first_name: string;
    last_name: string;
    phone_number: string;
}