import { Injectable } from "@angular/core";
import { CtSerializerHelperService, CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { FlowStep1 } from "../classes/flow-steps/flow-step1";
import { FlowStep3 } from "../classes/flow-steps/flow-step3";
import { FlowStep4 } from "../classes/flow-steps/flow-step4";
import { FlowService } from "../lib/flow.service";
import { FlowStep2 } from "../classes/flow-steps/flow-step2";
import { FLOW_STATE } from "../enums/flow-state";
import { FLOW_TYPE } from "../enums/flow-type.enum";
import { FileErrorsDictionary } from "../interfaces/excel-validation-result";
import { IAddressValidation, IValidationResult } from "../interfaces/address-validation";
import { FlowFile } from "../interfaces/flow-file";
import { SendTypeSendCategoryEnum } from "../interfaces/send-type-configuration";

@Injectable()
export class FlowStepsController {

  constructor(private _webapi: CtWebapiService, private flowService: FlowService, private serializerHelperService: CtSerializerHelperService) {
  }

  step1Save = (parameter: FlowStep1) => this.stepSave(FLOW_STATE.STEP_1, parameter);

  step2Save = (parameter: FlowStep2) => this.stepSave(FLOW_STATE.STEP_2, parameter);

  step3Save = (parameter: FlowStep3) => this.stepSave(FLOW_STATE.STEP_3, parameter);

  step4Save = (parameter: FlowStep4) => this.stepSave(FLOW_STATE.STEP_4, parameter);

  private stepSave(step: number, parameter: FlowStep1 | FlowStep2 | FlowStep3 | FlowStep4): Promise<StepSaveResult> {

    return new Promise<StepSaveResult>((resolve) => {

      const flowOid: number = this.flowService.activeFlow.Oid;

      if (flowOid) {

        parameter.FlowOid = flowOid;

      }

      const needsFormData: boolean = step === FLOW_STATE.STEP_2 || step === FLOW_STATE.STEP_3;

      if (needsFormData) {

        // @ts-ignore
        parameter = this.serializerHelperService.objectToFormData(parameter);

      }

      const request: DataRequest = new DataRequest(`/FlowSteps/Step${step}Save`);

      this._webapi
        .post(request, parameter)
        .subscribe((r: CtWebapiGenericResponse<StepSaveResult>) => resolve(r.Result));

    });

  }

  getCompositionVariableFields(): Promise<string[]> {

    return new Promise<string[]>((resolve) => {

      const request: DataRequest = new DataRequest(`/FlowSteps/GetCompositionVariableFields`)
        .setQueryParams({ hideError: true });

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<string[]>) => resolve(r.Result));

    });

  }

}

export interface StepSaveResult extends Step2Result {

  FlowOid: number;
  FlowState: FLOW_STATE;
  FlowType: FLOW_TYPE;
  FlowFiles?: FlowFile[];
  SendTypeSendCategory?: SendTypeSendCategoryEnum;
  SendTypeOid?: number;
  SendTypeDescription?: string;
  SendTypeName?: string;



}

export interface Step2Result {

  RecipientsImported?: boolean;
  FileErrors?: FileErrorsDictionary;
  ValidationResult?: IValidationResult | Array<IAddressValidation>;

}
