import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { ISignatureReturnDTO } from "../interfaces/digital-signature-result";
import { SendSignatureDTO } from "../interfaces/digital-signature-send";
import { SignatureReturnEventDTO } from "../interfaces/digital-signature-event";

@Injectable({
  providedIn: "root"
})
export class DigitalSignatureController {

  private controller: string = "/DocumentSign/";

  constructor(private _webapi: CtWebapiService) {
  }

  activateSignature(parameter: SendSignatureDTO): Promise<ISignatureReturnDTO | null> {

    return new Promise<ISignatureReturnDTO | null>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}ActivateSignature`);

      var a = {
        SignerInfo: parameter.SignerInfo,
        Token: parameter.Token
      }

      this._webapi
        .post(request, a)
        .subscribe((r: CtWebapiGenericResponse<ISignatureReturnDTO | null>) => {

          resolve(r.Result);

        });

    });

  }

  signatureSoftwareEventReturn(parameter: SignatureReturnEventDTO): Promise<SignatureReturnEventDTO | null> {

    return new Promise<SignatureReturnEventDTO | null>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}SignatureSoftwareEventReturn`);

      var a = {
        Token: parameter.token,
        Status: parameter.status,
        Description: parameter.description
      }

      this._webapi
        .post(request, a)
        .subscribe((r: CtWebapiGenericResponse<SignatureReturnEventDTO | null>) => {

          resolve(r.Result);

        });

    });

  }

}
