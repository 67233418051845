import { FLOW_TYPE } from "../enums/flow-type.enum";
import { COLOR_MODE } from "../enums/color-mode.enum";
import { PRINT_MODE } from "../enums/print-mode.enum";
import { FLOW_STATE } from "../enums/flow-state";
import { AddressData } from "./address-data";
import { FlowFile } from "../interfaces/flow-file";
import { FlowSendAreaEnum } from "../enums/flow-send-area.enum";
import { RecipientData } from "../interfaces/recipient-data";
import { SendTypeSendCategoryEnum } from "../interfaces/send-type-configuration";
import { CTBase } from "@ctsolution/ct-base";
import {
  DIGITAL_CHANNELS,
  EMAIL_STANDALONE_SEND_CATEGORY,
  OTP_STANDALONE_SEND_CATEGORY, PEC_STANDALONE_SEND_CATEGORY, SMS_STANDALONE_SEND_CATEGORY
} from "./send-categories.constant";

export class Flow extends CTBase<Flow> {

  Oid: number = 0;
  State: FLOW_STATE = FLOW_STATE.STEP_1;
  FlowFiles: FlowFile[] = [];
  SendTypeConfigurationSendTypeSendCategory: SendTypeSendCategoryEnum = SendTypeSendCategoryEnum.Letter;
  Type: FLOW_TYPE = FLOW_TYPE.SINGLE_RECIPIENT;
  TotalAmount: number = 0;

  SendTypeConfigurationSendTypeOid?: number;
  Description?: string;
  ColorMode?: COLOR_MODE;
  PrintMode?: PRINT_MODE;
  Error?: string;
  CreationDate?: string;

  FlowSenders: AddressData[] = new Array<AddressData>();
  InternalCode?: string;

  Recipients: RecipientData[] = new Array<RecipientData>();

  SendArea?: FlowSendAreaEnum;

  UserInfoName?: string;
  UserInfoSurname?: string;

  GroupName?: string;
  GroupCode?: string;

  SendDate?: string;
  EstimateAcceptDate?: string;
  StateDate?: string;

  SendTypeConfigurationSendTypeName?: string;

  SendSubject?: string;

  override createListElementClassByProperty(value: any, attribute: string): AddressData | RecipientData | FlowFile | null {

    switch (attribute) {

      case "FlowSenders":
        return AddressData.create(value);

      case "Recipients":
        return RecipientData.create(value);

      case "FlowFiles":
        return value as FlowFile;

      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  private constructor();
  private constructor(model?: Flow);
  private constructor(model?: Flow) {

    super();

    this.setFlowFiles([]);

    if (model) this.getClass(model);

  }

  public static create = (model?: Flow): Flow => new Flow(model);

  setFlowOid(value: number): Flow {

    this.Oid = value;
    return this;

  }

  setFlowState(value: FLOW_STATE): Flow {

    this.State = value;
    return this;

  }

  setFlowType(value: FLOW_TYPE): Flow {

    this.Type = value;
    return this;

  }

  setFlowFiles(value: FlowFile[]): Flow {

    this.FlowFiles = value;
    return this;

  }

  setSendTypeConfigurationSendTypeSendCategory(value: SendTypeSendCategoryEnum): Flow {

    this.SendTypeConfigurationSendTypeSendCategory = value;
    return this;

  }

  setSendTypeConfigurationSendTypeName(value: string): Flow {

    this.SendTypeConfigurationSendTypeName = value;
    return this;

  }

  setSendTypeConfigurationSendTypeOid(value: number): Flow {

    this.SendTypeConfigurationSendTypeOid = value;
    return this;

  }

  setTotalAmount(value: number): Flow {

    this.TotalAmount = value;
    return this;

  }

  get digitalSendingChannelSetupEnabled(): boolean {

    if (!this.SendTypeConfigurationSendTypeSendCategory) return false;

    return DIGITAL_CHANNELS.includes(this.SendTypeConfigurationSendTypeSendCategory);

  }

  /**
   * tutti i canali digitali tranne OTP (otp deve seguire il flusso delle lettere standard, 1 pdf solo)
   * */
  get digitalSendingStandaloneChannelSetupEnabled(): boolean {

    return this.digitalSendingChannelSetupEnabled && !this.OTPLetterStandaloneChannelSetupEnabled;

  }

  get smsStandaloneChannelSetupEnabled(): boolean {

    return this.digitalSendingChannelSetupEnabled && SMS_STANDALONE_SEND_CATEGORY.includes(this.SendTypeConfigurationSendTypeSendCategory);

  }

  get emailStandaloneChannelSetupEnabled(): boolean {

    return this.digitalSendingChannelSetupEnabled && EMAIL_STANDALONE_SEND_CATEGORY.includes(this.SendTypeConfigurationSendTypeSendCategory);

  }

  get OTPLetterStandaloneChannelSetupEnabled(): boolean {

    return this.digitalSendingChannelSetupEnabled && OTP_STANDALONE_SEND_CATEGORY.includes(this.SendTypeConfigurationSendTypeSendCategory);

  }

  get PECStandaloneChannelSetupEnabled(): boolean {

    return this.digitalSendingChannelSetupEnabled && PEC_STANDALONE_SEND_CATEGORY.includes(this.SendTypeConfigurationSendTypeSendCategory);

  }

  get letterStandaloneChannelSetupEnabled() {

    return [SendTypeSendCategoryEnum.Letter].includes(this.SendTypeConfigurationSendTypeSendCategory);

  }

  get dynamicRecipientsSetupEnabled(): boolean {

    return this.Type === FLOW_TYPE.MULTIPLE_RECIPIENT_DYNAMIC && !this.digitalSendingChannelSetupEnabled;

  }

  flowPdfAttachments(): FlowFile[] {

    return this.FlowFiles.filter((element: FlowFile) => element.Extension.toLowerCase().includes("pdf"));

  }

}
