import { Injectable } from "@angular/core";
import { FlowFile } from "../../core/interfaces/flow-file";
import { RecipientData } from "../../core/interfaces/recipient-data";
import { RecipientFlowPreviewParameter } from "../../core/classes/base-flow-file-parameter";
import { FLOW_FILE_TYPE } from "../../core/enums/flow-file-type.enum";
import { FileService } from "../../core/lib/file.service";

@Injectable()
export class TrackingService {

  constructor(private fileService: FileService) {
  }

  manageFile(file: FlowFile, recipient: RecipientData) {

    return new Promise((resolve, reject) => {
      debugger;
      (file as any).loading = true;

      let fileOperation: Promise<any>;

      if (file.PPFileType === FLOW_FILE_TYPE.PreviewRecipient) {

        const parameter: RecipientFlowPreviewParameter = RecipientFlowPreviewParameter.create().setRecipient(recipient);
        fileOperation = this.fileService.manageRecipientFlowPreview(parameter);

      } else {

        fileOperation = this.fileService.downloadFlowFile(file);

      }

      fileOperation
        .then(() => {

          (file as any).loading = false;
          resolve(true);

        })
        .catch((error) => {

          (file as any).loading = false;
          reject(error);

        });

    });

  }

}
