import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ProductListComponent } from "./product-list/product-list.component";
import { FlowStepsController, StepSaveResult } from "../../../../core/controllers/flow-steps.controller";
import { FlowStep1 } from "../../../../core/classes/flow-steps/flow-step1";
import { FlowService } from "../../../../core/lib/flow.service";
import { ActivatedRoute } from "@angular/router";
import { FLOW_TYPE } from "../../../../core/enums/flow-type.enum";
import { ISendTypeConfiguration } from "../../../../core/interfaces/send-type-configuration";
import { GeneralService } from "../../../../core/lib/general.service";

@Component({
  selector: "app-step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["./step1.component.scss"],
  providers: [FlowStepsController]
})
export class Step1Component {

  @ViewChild("ProductList") productList!: ProductListComponent;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSuccess: EventEmitter<StepSaveResult> = new EventEmitter<StepSaveResult>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private _controller: FlowStepsController,
    private flowService: FlowService,
    private route: ActivatedRoute,
    private general: GeneralService,
    private cdr : ChangeDetectorRef
  ) {

    this.form = this.fb.group({});

  }

  ngAfterViewInit() {

    this.form.addControl("product", this.productList!.control);
    this.productList.control.setParent(this.form);

    this.productList
      .getProducts()
      .then(() => this.fetch());


  }

  fetch() {

    const sendTypeOid: number = this.flowService.activeFlow.SendTypeConfigurationSendTypeOid ?? 0;

    if (sendTypeOid) {

      const product = this.productList
        .SEND_PRODUCTS
        .find((p: ISendTypeConfiguration) => p.SendTypeOid === sendTypeOid);

      if (product) {

        this.productList.control.setValue(product);

      }

    }

    this.route
    .data
    .subscribe(data => {

      data['title'] = `${data['urls'][0].title}`;
      this.cdr.detectChanges();

    })

  }

  /**
   * If the form is valid, create a new FlowStep1 object with the Oid of the selected product, and then call the step1Save
   * method on the controller, passing in the FlowStep1 object
   */
  submit() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      this.route
        .data
        .subscribe(routeData => {

          const sendTypeConfigurationValue: ISendTypeConfiguration = this.form.value.product as ISendTypeConfiguration;

          const parameter: FlowStep1 = new FlowStep1(sendTypeConfigurationValue.SendTypeOid, <FLOW_TYPE>routeData["FlowDraftType"]);

          if (sendTypeConfigurationValue.Oid) {

            parameter
              .setSendTypeConfigurationOid(sendTypeConfigurationValue.Oid);

          }

          this._controller
            .step1Save(parameter)
            .then((result: StepSaveResult) => {

              this.onSuccess.emit(result);

            });

        });

    } else {

      this.general.scrollToFirstInvalidControl();

    }

  }

}
