import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PersonSpecificationsComponent } from "./person-specifications/person-specifications.component";
import {
  LetterSpecifications,
  LetterSpecificationsComponent
} from "./letter-specifications/letter-specifications.component";
import { AddressData } from "../../../../core/classes/address-data";
import { FlowStepsController, StepSaveResult } from "../../../../core/controllers/flow-steps.controller";
import { FLOW_TYPE } from "../../../../core/enums/flow-type.enum";
import {
  AddressValidatorOptions,
  AddressValidatorService,
  Step2AddressValidations
} from "../address-validator/address-validator.service";
import { Subscription } from "rxjs";
import { distinctUntilChanged, pairwise } from "rxjs/operators";
import { FlowService } from "../../../../core/lib/flow.service";
import { RecipientListUploadComponent } from "./recipient-list-upload/recipient-list-upload.component";
import { FlowStep2 } from "../../../../core/classes/flow-steps/flow-step2";
import { FlowFile } from "../../../../core/interfaces/flow-file";
import { FLOW_FILE_TYPE } from "../../../../core/enums/flow-file-type.enum";
import { Group } from "../../../../core/interfaces/group";
import { FlowSendAreaEnum } from "../../../../core/enums/flow-send-area.enum";
import { GeneralService } from "../../../../core/lib/general.service";
import {
  IAddressValidation,
  ISuggestedAddressCorrection,
  IValidationResult
} from "../../../../core/interfaces/address-validation";
import { JwtService } from "../../../../core/lib/jwt.service";
import {
  PERSON_SPECIFICATION_TYPE,
  PersonSpecificationsConfiguration
} from "./person-specifications/person-specifications.configuration";
import { RecipientAddress } from "../../../../core/classes/recipient-address";
import { ActivatedRoute } from "@angular/router";

export const ZIP_CODE_MAX_LENGTH: number = 5;

@Component({
  selector: "app-step2",
  templateUrl: "./step2.component.html",
  styleUrls: ["./step2.component.scss"],
  providers: [FlowStepsController]
})
export class Step2Component {

  @ViewChild("sender") sender!: PersonSpecificationsComponent;
  senderPersonSpecificationsComponentConfiguration: PersonSpecificationsConfiguration = new PersonSpecificationsConfiguration("MITTENTE").setPersonSpecificationType(PERSON_SPECIFICATION_TYPE.SENDER);

  @ViewChild("recipient") recipient!: PersonSpecificationsComponent;
  recipientPersonSpecificationsComponentConfiguration: PersonSpecificationsConfiguration = new PersonSpecificationsConfiguration("DESTINATARIO").setPersonSpecificationType(PERSON_SPECIFICATION_TYPE.RECIPIENT);

  @ViewChild("multipleRecipients") multipleRecipients!: RecipientListUploadComponent;
  @ViewChild("specifications") specifications!: LetterSpecificationsComponent;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSuccess: EventEmitter<StepSaveResult> = new EventEmitter<StepSaveResult>();

  form: FormGroup;
  FLOW_TYPE = FLOW_TYPE;
  subscription?: Subscription;
  isGroupDetailsFetched = false;

  constructor(
    private fb: FormBuilder,
    private _controller: FlowStepsController,
    private cdr: ChangeDetectorRef,
    private _validator: AddressValidatorService,
    public flowService: FlowService,
    private general: GeneralService,
    private _jwt: JwtService,
    private route: ActivatedRoute
  ) {

    this.form = this.fb.group({});

  }

  ngAfterViewInit() {

    this.setSenderControl();

    this.setSpecificationsControl();

    this.cdr.detectChanges();

  }

  /**
   * The function fetches the data from the server and sets the form values
   */
  fetch() {

    if (this.isGroupDetailsFetched) {

      this.setupFlow();
      return;

    }

    this.specifications
      .setupGroupDetails()
      .then(async (group: Group) => {

        if (group.SenderData?.AutoFillSenderData) {

          this.sender.setFormValue(group.SenderData);

        }

        this.isGroupDetailsFetched = true;

        await this.setSenderConfigurations();

        this.setupFlow()
          .then();

      });

  }

  private async setupFlow() {

    const activeFlow = this.flowService.activeFlow;
    if (!activeFlow) return;

    const {
      ColorMode,
      PrintMode,
      SendArea,
      SendSubject,
      FlowSenders,
      Type,
      Recipients,
      digitalSendingChannelSetupEnabled
    } = activeFlow;

    if (ColorMode) this.specifications.form.get("ColorMode")?.setValue(ColorMode);
    if (PrintMode) this.specifications.form.get("PrintMode")?.setValue(PrintMode);
    if (SendArea) this.specifications.form.get("Destination")?.setValue(SendArea);
    if (SendSubject) this.specifications.form.get("SendSubject")?.setValue(SendSubject);

    if ((FlowSenders ?? []).length > 0) {

      this.sender.setFormValue(FlowSenders[0]);

    }

    switch (Type) {
      case FLOW_TYPE.SINGLE_RECIPIENT:

        this.setRecipientControl();

        if ((Recipients ?? []).length > 0) {

          this.recipient.setFormValue(Recipients[0]);

        }

        this.recipientPersonSpecificationsComponentConfiguration.setFlow(activeFlow);
        this.recipient.updateCurrentFlowValidators();
        break;

      case FLOW_TYPE.MULTIPLE_RECIPIENT:
      case FLOW_TYPE.MULTIPLE_RECIPIENT_DYNAMIC:
        this.setMultipleRecipientControl();
        this.setFlowRecipientsFile();
        break;
    }

    this.route
    .data
    .subscribe(data => {

      data['title'] = `${data['title']} - ${activeFlow.SendTypeConfigurationSendTypeName}`
      this.cdr.detectChanges();

    })

    this.form.updateValueAndValidity();
    this.cdr.detectChanges();

  }

  /**
   * The function is called when the user clicks the submit button. It checks if the form is valid, if it is, it checks if
   * the address is valid, if it is, it saves the form data to the server
   */
  submit(autoCorrectValidationResult: boolean = false) {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const specifications: LetterSpecifications = new LetterSpecifications(this.specifications.form.value);

      const flowStep2Data: FlowStep2 = new FlowStep2(
        specifications.Destination,
        this.senderValue(),
        specifications.ColorMode,
        specifications.PrintMode,
        specifications.SendSubject
      );

      if (autoCorrectValidationResult) {

        flowStep2Data.AutoCorrectValidationResult = true;

      }

      if (this.flowService.activeFlow.digitalSendingChannelSetupEnabled) {

        const senderFormValue = this.sender.form.getRawValue(); // per prendere anche i campi disabled
        const emailProvider = this.sender.configuration.emailProvider;
        const senderData = flowStep2Data.SenderData;

        senderData.Name = senderData.Name || senderFormValue.Name;
        senderData.Phone = senderData.Phone || senderFormValue.Phone;
        senderData.Email = senderData.Email || senderFormValue.Email;

        if ((senderData.Email ?? "").indexOf("@") < 0 && emailProvider) {

          senderData.Email += emailProvider;

        }

      }

      switch (this.flowService.activeFlow.Type) {

        case FLOW_TYPE.SINGLE_RECIPIENT:
          this.setupSingleRecipientDataParameter(flowStep2Data)
            .then((parameter: FlowStep2 | null) => {

              if (!parameter) return;

              this.saveSingleRecipientData(parameter);

            });

          break;

        case FLOW_TYPE.MULTIPLE_RECIPIENT:
        case FLOW_TYPE.MULTIPLE_RECIPIENT_DYNAMIC:
          this.saveMultipleRecipientData(flowStep2Data);
          break;

      }

    } else {

      this.general.scrollToFirstInvalidControl();

    }

  }

  //#region SPECIFICATIONS

  /* Subscribing to the valueChanges of the specifications form, and when the value of the destination changes, it calls
  the toggleDestinationValidation function of the recipient component. */
  private setSpecificationsControl() {

    this.form.addControl("specifications", this.specifications!.form);
    this.specifications.form.setParent(this.form);

    // subscribe al form di specifiche letter per capire quando il valore "destinazione" sta cambiando
    this.subscription = this.specifications.form
      .valueChanges
      .pipe(distinctUntilChanged(), pairwise() /*gets a pair of old and new value*/)
      .subscribe(([prev, next]: [any, any]) => {

        // possono cambiare i valori del form specifiche lettere, ma rimanere invariati quelli di destinazione, in tal caso non devo riapplicare le logiche per destinatario estero
        if (prev.Destination !== next.Destination && !this.multipleRecipients) {

          this.recipient.toggleDestinationValidation(next.Destination);

        }

      });

  };

  //#endregion

  //#region SENDER

  private async setSenderConfigurations() {

    const activeFlow = this.flowService.activeFlow;
    if (!activeFlow) return;

    this.senderPersonSpecificationsComponentConfiguration.setFlow(activeFlow);

    this.sender.updateCurrentFlowValidators();

    const companyConfiguration = await this._jwt.getCompanyCustomConfiguration();

    if (companyConfiguration) {

      const { SenderPrefix, SendCategorySenders } = companyConfiguration;

      if (SenderPrefix) {

        this.sender.setNamePatternConfiguration(SenderPrefix);

      }

      if (activeFlow.digitalSendingChannelSetupEnabled && (SendCategorySenders ?? []).length > 0) {

        this.sender.setNameDigitalConfiguration(SendCategorySenders ?? []);

      }

    }

  }

  /* A function that is called when the view is initialized. It adds the sender form to the main form and sets the main form as the parent of the sender form. */
  private setSenderControl() {

    this.form.addControl("sender", this.sender!.form);
    this.sender.form.setParent(this.form);

  };

  private senderValue = () => this._validator.handleDefaultLocalCountryValue(<AddressData>this.sender.form.value);

  //#endregion

  //#region SINGLE RECIPIENT

  /* Adding the recipient form to the main form and sets the main form as the parent of the recipient form. */
  private setRecipientControl() {

    this.form.addControl("recipient", this.recipient!.form);
    this.recipient.form.setParent(this.form);

  };

  /* A function that returns the recipient address data. It is called when the user clicks the submit button. */
  private getRecipientValue() {

    const RecipientData: RecipientAddress = this._validator.handleDefaultLocalCountryValue(<AddressData>this.recipient.form.value);

    if (this.specifications.form.value.Destination === FlowSendAreaEnum.External && (RecipientData.ZipCode?.length ?? 0) < ZIP_CODE_MAX_LENGTH) {

      RecipientData.ZipCode = this._validator.addLeadingZeros(+(RecipientData.ZipCode ?? 0), ZIP_CODE_MAX_LENGTH);

    }

    if (this.flowService.activeFlow.PECStandaloneChannelSetupEnabled && RecipientData.Email) {

      RecipientData.CertifiedEmail = RecipientData.Email;
      RecipientData.Email = null;

    }

    return RecipientData;

  };

  setupSingleRecipientDataParameter(flowStep2Data: FlowStep2): Promise<FlowStep2 | null> {

    return new Promise<FlowStep2 | null>((resolve) => {

      this.checkAddressValidations()
        .then((valid: boolean) => {

          let parameter: FlowStep2 | null = null;

          if (valid) {

            parameter = <FlowStep2>flowStep2Data;
            parameter.SingleRecipientData = this.getRecipientValue();

          }

          resolve(parameter);

        });

    });

  }

  saveSingleRecipientData(parameter: FlowStep2) {

    this._controller
      .step2Save(parameter)
      .then((result: StepSaveResult) => this.onSuccess.emit(result));

  }

  //#endregion

  //#region MULTIPLE RECIPIENT

  private setMultipleRecipientControl() {

    this.form.addControl("multipleRecipients", this.multipleRecipients.ctFileUploaderConfiguration.control);
    this.multipleRecipients.ctFileUploaderConfiguration.control.setParent(this.form);

  };

  setFlowRecipientsFile(event?: FlowFile[]) {

    if (event) {

      this.flowService
        .activeFlow
        .setFlowFiles(event);

    }

    const flowRecipientsFile: FlowFile | undefined = (this.flowService.activeFlow.FlowFiles ?? []).find((file: FlowFile) => file.PPFileType === FLOW_FILE_TYPE.RecipientList);

    this.multipleRecipients.setData(flowRecipientsFile ? [flowRecipientsFile] : []);

    this.cdr.detectChanges();

  }

  private saveMultipleRecipientData(flowStep2Data: FlowStep2) {

    const parameter: FlowStep2 = <FlowStep2>flowStep2Data;
    parameter.RecipientFile = this.multipleRecipients.ctFileUploaderConfiguration.control.value.filter((f: File) => !!f.lastModified);

    this._controller
      .step2Save(parameter)
      .then((result: StepSaveResult) => {

        if (result.RecipientsImported) {

          this.onSuccess.emit(result);

        } else {

          if (result.FileErrors || (result.ValidationResult as Array<IAddressValidation>).length) {

            this._validator
              .openMultipleRecipientsValidation(result)
              .afterClosed()
              .subscribe((AutoCorrectValidationResult?: boolean) => {

                if (AutoCorrectValidationResult) {

                  this.submit(true);

                } else {

                  this.multipleRecipients.reset();

                }

              });

          } else {

            this.multipleRecipients.reset();

          }

        }

      });

  }

  //#endregion

  //#region ADDRESS VALIDATIONS

  /**
   * It checks if the sender and recipient addresses are valid, and if they are not, it opens a modal with the address data
   * to be corrected
   * @returns A promise that resolves to a boolean.
   */
  private checkAddressValidations(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      if (this.senderPersonSpecificationsComponentConfiguration.verified && this.recipientPersonSpecificationsComponentConfiguration.verified) {

        resolve(true);
        return;

      }

      const senderAddressData: AddressData = this.senderValue();
      const recipientAddressData: AddressData = this.getRecipientValue();

      const opt: AddressValidatorOptions = new AddressValidatorOptions()
        .setSendArea(this.specifications.form.value.Destination)
        .setBypassSenderValidation(this.flowService.activeFlow.digitalSendingStandaloneChannelSetupEnabled ?? false);

      this._validator
        .checkContactDetailsValidations(senderAddressData, recipientAddressData, opt)
        .then((validations: Step2AddressValidations) => {

          this.senderPersonSpecificationsComponentConfiguration
            .setVerified(validations.senderValidation?.IsValid ?? false);

          this.recipientPersonSpecificationsComponentConfiguration
            .setVerified(validations.recipientValidation?.IsValid ?? false);

          if (!this.senderPersonSpecificationsComponentConfiguration.verified && !this.recipientPersonSpecificationsComponentConfiguration.verified) { // entrambi i form non sono stati validati, modale di errore e interazione manuale dell'utente

            this._validator.invalidAddressesAlert();
            resolve(false);
            return;

          }

          if (!this.senderPersonSpecificationsComponentConfiguration.verified) {               // solo il mittente non è stato validato; apro modale con i dati del mittente

            this.sender.validate();
            resolve(false);
            return;

          } else {

            // controllo se l'indirizzo è valido ma ha bisogno di essere formattato come da validazione
            this.checkIfAddressNeedsValidationSuggestion(this.sender, validations.senderValidation);

          }

          if (!this.recipientPersonSpecificationsComponentConfiguration.verified) {              // solo il destinatario non è stato validato; apro modale con i dati del destinatario

            this.recipient.validate();
            resolve(false);
            return;

          } else {

            // controllo se l'indirizzo è valido ma ha bisogno di essere formattato come da validazione
            this.checkIfAddressNeedsValidationSuggestion(this.recipient, validations.recipientValidation);

          }

          resolve(true);             //caso migliore, entrambi gli indirizzi sono validi, go on
          return;

        });

    });

  }

  private checkIfAddressNeedsValidationSuggestion(personSpecification: PersonSpecificationsComponent, validation: IValidationResult | null) {

    const corrections: ISuggestedAddressCorrection[] = validation?.SuggestedCorrections ?? [];

    if (validation?.IsValid && corrections.length === 1) {

      const correction: ISuggestedAddressCorrection = corrections[0];
      personSpecification.setFormValue(correction);

    }

  }

  //#endregion

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
